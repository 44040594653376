<template>
  <div class="site-footer">
    <div class="site-footer-content">

      <div class="top">
        <img class="logo" :src="LogoSrc" alt="TourHero Logo">
        <div>Bringing us closer as humans, one journey at a time.</div>
        <ContactInfo v-if="mobile" />
      </div>

      <div class="hr"></div>

      <div class="middle">
        <ContactInfo v-if="desktop" />

        <div class="links">
          <div class="heading">Get started</div>
          <a href="/en/plan-a-trip">Plan a trip</a>
          <a href="/en/mba-exclusive">MBA exclusive</a>
          <a href="/en/wellness-retreat">Wellness retreats</a>
          <a href="/en/content-creator-group-travel">Content creator</a>
        </div>

        <div class="links">
          <div class="heading">About</div>
          <a href="/en/about">About us</a>
          <a href="/en/magazine/">TourHero magazine</a>
          <a href="/en/team/">TourHero team</a>
          <a href="https://careers.tourhero.com/" target="_blank" ref="noreferrer noopener">Careers</a>
          <a href="/en/magazine/life-at-tourhero/">Life at TourHero</a>
          <a href="https://reviews.tourhero.com/" target="_blank" ref="noreferrer noopener">TourHero reviews</a>
          <a href="/en/about#contact_us">Contact us</a>
        </div>

        <div class="links">
          <div class="heading">Legal</div>
          <a href="/en/terms">Terms</a>
          <a href="/en/privacy-policy">Privacy policy</a>
        </div>
      </div>

      <div class="hr"></div>

      <div class="bottom">
        <div class="disclaimer">© 2024 TourHero Inc. All rights reserved</div>
        <Socials />
      </div>

    </div>
  </div>
</template>

<script setup>
import LogoSrc from 'app/assets/images/logo.png';
import Socials from "@/modules/SiteFooter/Socials.vue";
import { useShared } from "@/helpers/vueHelpers.js";
import ContactInfo from "@/modules/SiteFooter/ContactInfo.vue";

const { mobile, desktop } = useShared();
</script>

<style lang="scss" scoped>
.site-footer {
  background-color: #E3F2FD;
  border-radius: 24px 24px 0 0;
  padding: 32px 0;

  @include mobile {
    padding: 24px 0;
  }
}

.site-footer-content {
  @include main-container;
  @include main-wrapper;

  font-size: $text-base;
  line-height: $leading-snug;
  font-weight: 500;
  color: $brand-primary;

  .hr {
    opacity: .5;
    margin: 32px 0;
    border-bottom: 1px solid $blue-75;
  }

  .top {
    display: grid;
    gap: 24px;

    .logo {
      height: 32px;
    }
  }

  .middle {
    display: grid;
    grid-template-columns: 1fr auto auto auto;
    row-gap: 32px;
    column-gap: 50px;
    align-items: start;

    @include mobile {
      grid-template-columns: 1fr 1fr;
    }

    .links {
      display: flex;
      flex-direction: column;
      row-gap: 16px;

      .heading {
        font-size: 12px;
        line-height: 14px;
        font-weight: 600;
        text-transform: uppercase;
        margin-bottom: 16px;
      }

      a {
        color: $brand-primary;
      }
    }
  }

  .bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;

    @include mobile {
      align-items: flex-start;
      flex-direction: column-reverse;
    }
  }
}
</style>